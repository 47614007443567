var $ = jQuery.noConflict();

$.fn.inlineStyle = function (prop) {
	return this.prop("style")[$.camelCase(prop)];
};

$.fn.doOnce = function( func ) {
	this.length && func.apply( this );
	return this;
}

var addAnimations = function (animations, trigger, hook, offset, duration, controller) {
	var tween = new TimelineMax();
	tween.insertMultiple(animations, 0, 0);

	if (hook && duration) {
		var scene = new ScrollMagic.Scene({
			triggerElement: trigger,
			triggerHook: hook,
			offset: offset,
			duration: duration
		})
				.setTween(tween)
				.addTo(controller);
	} else if (duration) {
		var scene = new ScrollMagic.Scene({
			triggerElement: trigger,
			offset: offset,
			duration: duration
		})
				.setTween(tween)
				.addTo(controller);
	} else {
		var scene = new ScrollMagic.Scene({
			triggerElement: trigger,
			offset: offset
		})
				.setTween(tween)
				.addTo(controller);
	}

	return;
};

var BRETTBASH = BRETTBASH || {};

(function($){

	// USE STRICT
	"use strict";

	BRETTBASH.initialize = {

		init: function() {
			BRETTBASH.initialize.responsiveClasses();
			BRETTBASH.initialize.imagePreload( '.portfolio-item:not(:has(.fslider)) img' );
			BRETTBASH.initialize.stickyElements();
			BRETTBASH.initialize.goToTop();
			BRETTBASH.initialize.fullScreen();
			BRETTBASH.initialize.verticalMiddle();
			BRETTBASH.initialize.lightbox();
			BRETTBASH.initialize.resizeVideos();
			BRETTBASH.initialize.imageFade();
			BRETTBASH.initialize.dataStyles();
			BRETTBASH.initialize.dataResponsiveHeights();

			$('.fslider').addClass('preloader2');

		},

		responsiveClasses: function(){
			var jRes = jRespond([
				{
					label: 'smallest',
					enter: 0,
					exit: 479
				},{
					label: 'handheld',
					enter: 480,
					exit: 767
				},{
					label: 'tablet',
					enter: 768,
					exit: 991
				},{
					label: 'laptop',
					enter: 992,
					exit: 1199
				},{
					label: 'desktop',
					enter: 1200,
					exit: 10000
				}
			]);
			jRes.addFunc([
				{
					breakpoint: 'desktop',
					enter: function() { $body.addClass('device-lg'); },
					exit: function() { $body.removeClass('device-lg'); }
				},{
					breakpoint: 'laptop',
					enter: function() { $body.addClass('device-md'); },
					exit: function() { $body.removeClass('device-md'); }
				},{
					breakpoint: 'tablet',
					enter: function() { $body.addClass('device-sm'); },
					exit: function() { $body.removeClass('device-sm'); }
				},{
					breakpoint: 'handheld',
					enter: function() { $body.addClass('device-xs'); },
					exit: function() { $body.removeClass('device-xs'); }
				},{
					breakpoint: 'smallest',
					enter: function() { $body.addClass('device-xxs'); },
					exit: function() { $body.removeClass('device-xxs'); }
				}
			]);
		},

		imagePreload: function(selector, parameters){
			var params = {
				delay: 250,
				transition: 400,
				easing: 'linear'
			};
			$.extend(params, parameters);

			$(selector).each(function() {
				var image = $(this);
				image.css({visibility:'hidden', opacity: 0, display:'block'});
				image.wrap('<span class="preloader" />');
				image.one("load", function(evt) {
					$(this).delay(params.delay).css({visibility:'visible'}).animate({opacity: 1}, params.transition, params.easing, function() {
						$(this).unwrap('<span class="preloader" />');
					});
				}).each(function() {
					if(this.complete) $(this).trigger("load");
				});
			});
		},

		verticalMiddle: function(){
			if( $verticalMiddleEl.length > 0 ) {
				$verticalMiddleEl.each( function(){
					var element = $(this),
						verticalMiddleH = element.outerHeight();

					if( element.parents('#slider').length > 0 ) {
						if( $header.hasClass('transparent-header') && ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) ) {
							verticalMiddleH = verticalMiddleH - 70;
							if( $slider.next('#header').length > 0 ) { verticalMiddleH = verticalMiddleH + 100; }
						}
					}

					if( $body.hasClass('device-xs') || $body.hasClass('device-xxs') ) {
						if( element.parents('.full-screen').length && !element.parents('.force-full-screen').length ){
							element.css({ position: 'relative', top: '0', width: 'auto', marginTop: '0', padding: '60px 0' }).addClass('clearfix');
						} else {
							element.css({ position: 'absolute', top: '50%', width: '100%', marginTop: -(verticalMiddleH/2)+'px' });
						}
					} else {
						element.css({ position: 'absolute', top: '50%', width: '100%', marginTop: -(verticalMiddleH/2)+'px' });
					}
				});
			}
		},

		stickyElements: function(){
			if( $siStickyEl.length > 0 ) {
				var siStickyH = $siStickyEl.outerHeight();
				$siStickyEl.css({ marginTop: -(siStickyH/2)+'px' });
			}

			if( $dotsMenuEl.length > 0 ) {
				var opmdStickyH = $dotsMenuEl.outerHeight();
				$dotsMenuEl.css({ marginTop: -(opmdStickyH/2)+'px' });
			}
		},

		goToTop: function(){
			$goToTopEl.click(function() {
				$('body,html').stop(true).animate({scrollTop:0},400);
				return false;
			});
		},

		goToTopScroll: function(){
			if( $body.hasClass('device-lg') || $body.hasClass('device-md') || $body.hasClass('device-sm') ) {
				if($window.scrollTop() > 450) {
					$goToTopEl.fadeIn();
				} else {
					$goToTopEl.fadeOut();
				}
			}
		},

		fullScreen: function(){
			if( $fullScreenEl.length > 0 ) {
				$fullScreenEl.each( function(){
					var element = $(this),
						scrHeight = $window.height();
					if( element.attr('id') == 'slider' ) {
						var sliderHeightOff = $slider.offset().top;
						scrHeight = scrHeight - sliderHeightOff;
						if( $('#slider.with-header').next('#header:not(.transparent-header)').length > 0 && ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) ) {
							var headerHeightOff = $header.outerHeight();
							scrHeight = scrHeight - headerHeightOff;
						}
					}
					if( element.parents('.full-screen').length > 0 ) { scrHeight = element.parents('.full-screen').height(); }

					if( $body.hasClass('device-xs') || $body.hasClass('device-xxs') ) {
						if( !element.hasClass('force-full-screen') ){ scrHeight = 'auto'; }
					}

					element.css('height', scrHeight);
					if( element.attr('id') == 'slider' && !element.hasClass('canvas-slider-grid') ) { if( element.has('.swiper-slide') ) { element.find('.swiper-slide').css('height', scrHeight); } }
				});
			}
		},

		maxHeight: function(){
			if( $commonHeightEl.length > 0 ) {
				$commonHeightEl.each( function(){
					var parentEl = $(this),
						maxHeight = 0;
					parentEl.children('[class^=col-]').each(function() {
						var element = $(this).children('div');
						if( element.hasClass('max-height') ){
							maxHeight = element.outerHeight();
						} else {
							if (element.outerHeight() > maxHeight)
							maxHeight = element.outerHeight();
						}
					});

					parentEl.children('[class^=col-]').each(function() {
						$(this).height(maxHeight);
					});
				});
			}
		},

		testimonialsGrid: function(){
			if( $testimonialsGridEl.length > 0 ) {
				if( $body.hasClass('device-sm') || $body.hasClass('device-md') || $body.hasClass('device-lg') ) {
					var maxHeight = 0;
					$testimonialsGridEl.each( function(){
						$(this).find("li > .testimonial").each(function(){
						   if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
						});
						$(this).find("li").height(maxHeight);
						maxHeight = 0;
					});
				} else {
					$testimonialsGridEl.find("li").css({ 'height': 'auto' });
				}
			}
		},

		contactForm: function(){
			$("#contactform").validate({
                submitHandler: function(form) {
                    // $('.form-process').fadeIn();
                    $(form).ajaxSubmit({
                        target: '#contact-form-result',
                        success: function() {
                            // $('.form-process').fadeOut();
                            $('#contactform').find('.sm-form-control').val('');
                            $('#contact-form-result').attr('data-notify-msg', $('#contact-form-result').html()).html('');
                            BRETTBASH.widget.notifications($('#contact-form-result'));
                        }
                    });
                }
            });
		},

		lightbox: function(){
			var $lightboxImageEl = $('[data-lightbox="image"]'),
				$lightboxGalleryEl = $('[data-lightbox="gallery"]'),
				$lightboxIframeEl = $('[data-lightbox="iframe"]'),
				$lightboxAjaxEl = $('[data-lightbox="ajax"]'),
				$lightboxAjaxGalleryEl = $('[data-lightbox="ajax-gallery"]');

			if( $lightboxImageEl.length > 0 ) {
				$lightboxImageEl.magnificPopup({
					type: 'image',
					closeOnContentClick: true,
					closeBtnInside: false,
					fixedContentPos: true,
					mainClass: 'mfp-no-margins mfp-fade', // class to remove default margin from left and right side
					image: {
						verticalFit: true
					}
				});
			}

			if( $lightboxGalleryEl.length > 0 ) {
				$lightboxGalleryEl.each(function() {
					var element = $(this);

					if( element.find('a[data-lightbox="gallery-item"]').parent('.clone').hasClass('clone') ) {
						element.find('a[data-lightbox="gallery-item"]').parent('.clone').find('a[data-lightbox="gallery-item"]').attr('data-lightbox','');
					}

					element.magnificPopup({
						delegate: 'a[data-lightbox="gallery-item"]',
						type: 'image',
						closeOnContentClick: true,
						closeBtnInside: false,
						fixedContentPos: true,
						mainClass: 'mfp-no-margins mfp-fade', // class to remove default margin from left and right side
						image: {
							verticalFit: true
						},
						gallery: {
							enabled: true,
							navigateByImgClick: true,
							preload: [0,1] // Will preload 0 - before current, and 1 after the current image
						}
					});
				});
			}

			if( $lightboxIframeEl.length > 0 ) {
				$lightboxIframeEl.magnificPopup({
					disableOn: 600,
					type: 'iframe',
					removalDelay: 160,
					preloader: false,
					fixedContentPos: false
				});
			}

			if( $lightboxAjaxEl.length > 0 ) {
				$lightboxAjaxEl.magnificPopup({
					type: 'ajax',
					closeBtnInside: false,
					callbacks: {
						ajaxContentAdded: function(mfpResponse) {
							BRETTBASH.widget.loadFlexSlider();
							BRETTBASH.initialize.resizeVideos();
						},
						open: function() {
							$body.addClass('ohidden');
						},
						close: function() {
							$body.removeClass('ohidden');
						}
					}
				});
			}

			if( $lightboxAjaxGalleryEl.length > 0 ) {
				$lightboxAjaxGalleryEl.magnificPopup({
					delegate: 'a[data-lightbox="ajax-gallery-item"]',
					type: 'ajax',
					closeBtnInside: false,
					gallery: {
						enabled: true,
						preload: 0,
						navigateByImgClick: false
					},
					callbacks: {
						ajaxContentAdded: function(mfpResponse) {
							BRETTBASH.widget.loadFlexSlider();
							BRETTBASH.initialize.resizeVideos();
						},
						open: function() {
							$body.addClass('ohidden');
						},
						close: function() {
							$body.removeClass('ohidden');
						}
					}
				});
			}
		},

		resizeVideos: function(){
			if ( $().fitVids ) {
				$("#content,#footer,#slider:not(.revslider-wrap),.landing-offer-media,.portfolio-ajax-modal").fitVids({
					customSelector: "iframe[src^='http://www.dailymotion.com/embed']",
					ignore: '.no-fv'
				});
			}
		},

		imageFade: function(){
			$('.image_fade').hover( function(){
				$(this).filter(':not(:animated)').animate({opacity: 0.8}, 400);
			}, function() {
				$(this).animate({opacity: 1}, 400);
			});
		},

		blogTimelineEntries: function(){
			$('.post-timeline.grid-2').find('.entry').each( function(){
				var position = $(this).inlineStyle('left');
				if( position == '0px' ) {
					$(this).removeClass('alt');
				} else {
					$(this).addClass('alt');
				}
				$(this).find('.entry-timeline').fadeIn();
			});
		},

		topScrollOffset: function() {
			var topOffsetScroll = 0;

			if( ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) && !BRETTBASH.isMobile.any() ) {
				if( $header.hasClass('sticky-header') ) {
					if( $pagemenu.hasClass('dots-menu') ) { topOffsetScroll = 100; } else { topOffsetScroll = 144; }
				} else {
					if( $pagemenu.hasClass('dots-menu') ) { topOffsetScroll = 140; } else { topOffsetScroll = 184; }
				}

				if( !$pagemenu.length ) {
					if( $header.hasClass('sticky-header') ) { topOffsetScroll = 100; } else { topOffsetScroll = 140; }
				}
			} else {
				topOffsetScroll = 40;
			}

			return topOffsetScroll;
		},

		defineColumns: function( element ){
			var column = 4;

			if( element.hasClass('portfolio-full') ) {
				if( element.hasClass('portfolio-3') ) column = 3;
				else if( element.hasClass('portfolio-5') ) column = 5;
				else if( element.hasClass('portfolio-6') ) column = 6;
				else column = 4;

				if( $body.hasClass('device-sm') && ( column == 4 || column == 5 || column == 6 ) ) {
					column = 3;
				} else if( $body.hasClass('device-xs') && ( column == 3 || column == 4 || column == 5 || column == 6 ) ) {
					column = 2;
				} else if( $body.hasClass('device-xxs') ) {
					column = 1;
				}
			} else if( element.hasClass('masonry-thumbs') ) {
				if( element.hasClass('col-2') ) column = 2;
				else if( element.hasClass('col-3') ) column = 3;
				else if( element.hasClass('col-5') ) column = 5;
				else if( element.hasClass('col-6') ) column = 6;
				else column = 4;
			}

			return column;
		},

		setFullColumnWidth: function( element ){

			if( element.hasClass('portfolio-full') ) {
				var columns = BRETTBASH.initialize.defineColumns( element );
				var containerWidth = element.width();
				if( containerWidth == ( Math.floor(containerWidth/columns) * columns ) ) { containerWidth = containerWidth - 1; }
				var postWidth = Math.floor(containerWidth/columns);
				if( $body.hasClass('device-xxs') ) { var deviceSmallest = 1; } else { var deviceSmallest = 0; }
				element.find(".portfolio-item").each(function(index){
					if( deviceSmallest == 0 && $(this).hasClass('wide') ) { var elementSize = ( postWidth*2 ); } else { var elementSize = postWidth; }
					$(this).css({"width":elementSize+"px"});
				});
			} else if( element.hasClass('masonry-thumbs') ) {
				var columns = BRETTBASH.initialize.defineColumns( element ),
					containerWidth = element.innerWidth(),
					windowWidth = $window.width();
				if( containerWidth == windowWidth ){
					containerWidth = windowWidth*1.004;
					element.css({ 'width': containerWidth+'px' });
				}
				var postWidth = (containerWidth/columns);

				postWidth = Math.floor(postWidth);

				if( ( postWidth * columns ) >= containerWidth ) { element.css({ 'margin-right': '-1px' }); }

				element.children('a').css({"width":postWidth+"px"});

				var bigImageNumber = element.attr('data-big');
				if( bigImageNumber ) { bigImageNumber = Number(bigImageNumber) - 1; }
				var firstElementWidth = element.find('a:eq(0)').outerWidth();

				element.isotope({
					masonry: {
						columnWidth: firstElementWidth
					}
				});

				if( $.isNumeric( bigImageNumber ) ) {
					var t = setTimeout( function(){
						element.find('a:eq('+bigImageNumber+')').css({ width: firstElementWidth*2 + 'px' });
						element.isotope('layout');
					}, 1000 );
				}
			}

		},

		aspectResizer: function(){
			var $aspectResizerEl = $('.aspect-resizer');
			if( $aspectResizerEl.length > 0 ) {
				$aspectResizerEl.each( function(){
					var element = $(this),
						elementW = element.inlineStyle('width'),
						elementH = element.inlineStyle('height'),
						elementPW = element.parent().innerWidth();
				});
			}
		},

		dataStyles: function(){
			var $dataStyleXxs = $('[data-style-xxs]'),
				$dataStyleXs = $('[data-style-xs]'),
				$dataStyleSm = $('[data-style-sm]'),
				$dataStyleMd = $('[data-style-md]'),
				$dataStyleLg = $('[data-style-lg]');

			if( $dataStyleXxs.length > 0 ) {
				$dataStyleXxs.each( function(){
					var element = $(this),
						elementStyle = element.attr('data-style-xxs');

					if( $body.hasClass('device-xxs') ) {
						if( elementStyle != '' ) { element.attr( 'style', elementStyle ); }
					}
				});
			}

			if( $dataStyleXs.length > 0 ) {
				$dataStyleXs.each( function(){
					var element = $(this),
						elementStyle = element.attr('data-style-xs');

					if( $body.hasClass('device-xs') ) {
						if( elementStyle != '' ) { element.attr( 'style', elementStyle ); }
					}
				});
			}

			if( $dataStyleSm.length > 0 ) {
				$dataStyleSm.each( function(){
					var element = $(this),
						elementStyle = element.attr('data-style-sm');

					if( $body.hasClass('device-sm') ) {
						if( elementStyle != '' ) { element.attr( 'style', elementStyle ); }
					}
				});
			}

			if( $dataStyleMd.length > 0 ) {
				$dataStyleMd.each( function(){
					var element = $(this),
						elementStyle = element.attr('data-style-md');

					if( $body.hasClass('device-md') ) {
						if( elementStyle != '' ) { element.attr( 'style', elementStyle ); }
					}
				});
			}

			if( $dataStyleLg.length > 0 ) {
				$dataStyleLg.each( function(){
					var element = $(this),
						elementStyle = element.attr('data-style-lg');

					if( $body.hasClass('device-lg') ) {
						if( elementStyle != '' ) { element.attr( 'style', elementStyle ); }
					}
				});
			}
		},

		dataResponsiveHeights: function(){
			var $dataHeightXxs = $('[data-height-xxs]'),
				$dataHeightXs = $('[data-height-xs]'),
				$dataHeightSm = $('[data-height-sm]'),
				$dataHeightMd = $('[data-height-md]'),
				$dataHeightLg = $('[data-height-lg]');

			if( $dataHeightXxs.length > 0 ) {
				$dataHeightXxs.each( function(){
					var element = $(this),
						elementHeight = element.attr('data-height-xxs');

					if( $body.hasClass('device-xxs') ) {
						if( elementHeight != '' ) { element.css( 'height', elementHeight ); }
					}
				});
			}

			if( $dataHeightXs.length > 0 ) {
				$dataHeightXs.each( function(){
					var element = $(this),
						elementHeight = element.attr('data-height-xs');

					if( $body.hasClass('device-xs') ) {
						if( elementHeight != '' ) { element.css( 'height', elementHeight ); }
					}
				});
			}

			if( $dataHeightSm.length > 0 ) {
				$dataHeightSm.each( function(){
					var element = $(this),
						elementHeight = element.attr('data-height-sm');

					if( $body.hasClass('device-sm') ) {
						if( elementHeight != '' ) { element.css( 'height', elementHeight ); }
					}
				});
			}

			if( $dataHeightMd.length > 0 ) {
				$dataHeightMd.each( function(){
					var element = $(this),
						elementHeight = element.attr('data-height-md');

					if( $body.hasClass('device-md') ) {
						if( elementHeight != '' ) { element.css( 'height', elementHeight ); }
					}
				});
			}

			if( $dataHeightLg.length > 0 ) {
				$dataHeightLg.each( function(){
					var element = $(this),
						elementHeight = element.attr('data-height-lg');

					if( $body.hasClass('device-lg') ) {
						if( elementHeight != '' ) { element.css( 'height', elementHeight ); }
					}
				});
			}
		}

	};

	BRETTBASH.header = {

		init: function() {

			BRETTBASH.header.kepler();
			BRETTBASH.header.onepageScroller();
			BRETTBASH.header.bbLogo();

		},

		kepler: function() {
			// Hamburger Sound
			if(($body.hasClass('device-lg')) || ($body.hasClass('device-md'))) {
				var tik = $("#tik")[0];
				$(".bb-3d-nav-trigger").mouseenter(function() {
					tik.play();
				});
			}

			// Navigation Sounds
			if(($body.hasClass('device-lg')) || ($body.hasClass('device-md'))) {
				$(".bb-3d-nav a").each(function(i) {
					if (i !== 0) {
						$("#tink")
						.clone()
						.attr("id", "tink" + i)
						.appendTo($(this).parent());
					}
				$(this).data("tinker", i);
				})
				.mouseenter(function() {
					$("#tink" + $(this).data("tinker"))[0].play();
				});
				$("#tink").attr("id", "tinker0");
			}

			//toggle 3d navigation
			$('.bb-3d-nav-trigger').on('click', function(){
				if(($body.hasClass('device-lg')) || ($body.hasClass('device-md'))) {
					var roll02 = $("#roll-02")[0];
					roll02.play();
				}
				toggle3dBlock(!$('.bb-header').hasClass('nav-is-visible'));

				if ($('.bb-header').hasClass('nav-is-visible')){
					$("body").css("overflow", "hidden");
				} else {
					$("body").css("overflow", "auto");
				}
			});

			//select a new item from the 3d navigation
			$('.bb-3d-nav a').on('click', function(){
				var selected = $(this);

				selected.parent('li').addClass('bb-selected').siblings('li').removeClass('bb-selected');
				if(($body.hasClass('device-lg')) || ($body.hasClass('device-md'))) {
					var booom = $("#booom")[0];
					booom.play();
				}
				updateSelectedNav('close');

			});

			$(window).on('resize', function(){
				window.requestAnimationFrame(updateSelectedNav);
			});

			function toggle3dBlock(addOrRemove) {
				if(typeof(addOrRemove)==='undefined') addOrRemove = true;
				$('.bb-header').toggleClass('nav-is-visible', addOrRemove);
				$('main').toggleClass('nav-is-visible', addOrRemove);
				$('.bb-3d-nav-container').toggleClass('nav-is-visible', addOrRemove);
				$("body").css("overflow", "auto");
			}

			//this function update the .bb-marker position
			function updateSelectedNav(type) {
				var selectedItem = $('.bb-selected'),
					selectedItemPosition = selectedItem.index() + 1,
					leftPosition = selectedItem.offset().left,
					backgroundColor = selectedItem.data('color');

					var selected = $('.bb-selected > a'),
					divScrollToAnchor = selected.attr('data-menu-scrollto'),
					divScrollSpeed = selected.attr('data-speed'),
					divScrollOffset = selected.attr('data-offset'),
					divScrollEasing = selected.attr('data-easing');

				$('.bb-marker').removeClassPrefix('color').addClass('color-'+ selectedItemPosition).css({
					'left': leftPosition,
				});
				if( type == 'close') {
					$('.bb-marker').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
						toggle3dBlock(false);

						$('.bb-header').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
							if( !divScrollSpeed ) { divScrollSpeed = 750; }
							if( !divScrollOffset ) { divScrollOffset = 0; }
							if( !divScrollEasing ) { divScrollEasing = 'easeOutQuad'; }

							$('html,body').stop(true).animate({
								'scrollTop': $( divScrollToAnchor ).offset().top - Number(divScrollOffset)
							}, Number(divScrollSpeed), divScrollEasing);

						});
					});
				}
			}

			$.fn.removeClassPrefix = function(prefix) {
				this.each(function(i, el) {
					var classes = el.className.split(" ").filter(function(c) {
						return c.lastIndexOf(prefix, 0) !== 0;
					});
					el.className = $.trim(classes.join(" "));
				});
				return this;
			};
		},

		onepageScroller: function(){
			$onePageMenuEl.find('a[data-menu-scrollto="#' + BRETTBASH.header.onePageCurrentSection() + '"]').parent('li').addClass('bb-selected').siblings('li').removeClass('bb-selected');

			var selectedItem = $('.bb-selected'),
					selectedItemPosition = selectedItem.index() + 1,
					leftPosition = selectedItem.offset().left;

					// if .cdmarker has class of color-5, then add margin
					if (selectedItemPosition === '5') {
						leftPosition = selectedItem.offset('0');
					}

				/*$('.bb-marker').removeClassPrefix('color').addClass('color-'+ selectedItemPosition).css({
					'left': 'leftPosition',
				});*/
		},

		onePageCurrentSection: function(){
			var currentOnePageSection = 'home';

			$pageSectionEl.each(function(index) {
				var h = $(this).offset().top;
				var y = $window.scrollTop();

				if( y + 150 >= h && y < h + $(this).height() && $(this).attr('id') != currentOnePageSection ) {
					currentOnePageSection = $(this).attr('id');
				}
			});

			return currentOnePageSection;
		},

		bbLogo: function() {
			var controller = new ScrollMagic.Controller();
			addAnimations([
				TweenMax.fromTo("#logo .outer-circle-left", 0.3, {
					opacity: "0", x: -20, }, {
					opacity: "1", x: 0, ease: Circ.easeOut
				}),
				TweenMax.fromTo("#logo .outer-circle-right", 0.3, {
					opacity: "0", x: 20, }, {
					opacity: "1", x: 0, delay: 0.2, ease: Circ.easeOut
				}),
				TweenMax.to("#logo .left-bottom-hemisphere", 0.3, {opacity: "1", delay: 0.3, ease: Power1.easeOut}),
				TweenMax.to("#logo .right-bottom-hemisphere", 0.4, {opacity: "1", delay: 0.45, ease: Power1.easeOut}),
				TweenMax.to("#logo .right-top-hemisphere", 0.3, {opacity: "1", delay: 0.4, ease: Power1.easeOut}),
				TweenMax.to("#logo .left-top-hemisphere", 0.4, {opacity: "1", delay: 0.55, ease: Power1.easeOut}),
				TweenMax.fromTo("#logo .top-division", 0.3, {
					opacity: "0", y: 20, }, {
					opacity: "1", y: 0, delay: 0.6, ease: Circ.easeOut
				}),
				TweenMax.fromTo("#logo .bottom-division", 0.3, {
					opacity: "0", y: -20, }, {
					opacity: "1", y: 0, delay: 0.6, ease: Circ.easeOut
				}),
				TweenMax.to("#logo .right-b", 0.4, {opacity: "1", delay: 0.8, ease: Power1.easeOut}),
				TweenMax.to("#logo .left-b", 0.4, {opacity: "1", delay: 0.87, ease: Power1.easeOut})
			], '#content', '', -200, '',controller);
		}
	};

	BRETTBASH.slider = {

		init: function() {

			BRETTBASH.slider.sliderParallax();
			BRETTBASH.slider.sliderElementsFade();
			BRETTBASH.slider.captionPosition();
		},

		sliderParallaxOffset: function(){
			var sliderParallaxOffsetTop = 0;
			var headerHeight = $header.outerHeight();
			if( $body.hasClass('side-header') || $header.hasClass('transparent-header') ) { headerHeight = 0; }
			if( $pageTitle.length > 0 ) {
				var pageTitleHeight = $pageTitle.outerHeight();
				sliderParallaxOffsetTop = pageTitleHeight + headerHeight;
			} else {
				sliderParallaxOffsetTop = headerHeight;
			}

			if( $slider.next('#header').length > 0 ) { sliderParallaxOffsetTop = 0; }

			return sliderParallaxOffsetTop;
		},

		sliderParallax: function(){
			if( ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) && !BRETTBASH.isMobile.any() ) {
				var parallaxOffsetTop = BRETTBASH.slider.sliderParallaxOffset();
				if ($window.scrollTop() > parallaxOffsetTop) {
					$sliderParallaxEl.css({ 'transform' : 'translate(0,'+ (($window.scrollTop()-parallaxOffsetTop) / 1.5 ) +'px)' });
					$('.slider-parallax .slider-caption,.ei-title').css({ 'transform' : 'translate(0,-'+ (($window.scrollTop()-parallaxOffsetTop) / 7 ) +'px)' });
				} else {
					$('.slider-parallax,.slider-parallax .slider-caption,.ei-title').css({ 'transform' : 'translate(0,0)' });
				}
			} else {
				$('.slider-parallax,.slider-parallax .slider-caption,.ei-title').css({ 'transform' : 'translate(0,0)' });
			}
		},

		sliderElementsFade: function(){
			if( ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) && !BRETTBASH.isMobile.any() ) {
				var parallaxOffsetTop = BRETTBASH.slider.sliderParallaxOffset();
				if( $slider.length > 0 ) {
					if( $header.hasClass('transparent-header') || $('body').hasClass('side-header') ) { var tHeaderOffset = 100; } else { var tHeaderOffset = 0; }
					$sliderParallaxEl.find('#slider-arrow-left,#slider-arrow-right,.vertical-middle:not(.no-fade),.slider-caption,.ei-title,.camera_prev,.camera_next').css({'opacity':( ( 100 + ( $slider.offset().top + parallaxOffsetTop + tHeaderOffset ) - $window.scrollTop() ) ) /90});
				}
			} else {
				$sliderParallaxEl.find('#slider-arrow-left,#slider-arrow-right,.vertical-middle:not(.no-fade),.slider-caption,.ei-title,.camera_prev,.camera_next').css({'opacity': 1});
			}
		},

		captionPosition: function(){
			$slider.find('.slider-caption').each(function(){
				var scapHeight = $(this).outerHeight();
				var scapSliderHeight = $slider.outerHeight();
				if( $(this).parents('#slider').prev('#header').hasClass('transparent-header') && ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) ) {
					if( $(this).parents('#slider').prev('#header').hasClass('floating-header') ) {
						$(this).css({ top: ( scapSliderHeight + 160 - scapHeight ) / 2 + 'px' });
					} else {
						$(this).css({ top: ( scapSliderHeight + 100 - scapHeight ) / 2 + 'px' });
					}
				} else {
					$(this).css({ top: ( scapSliderHeight - scapHeight ) / 2 + 'px' });
				}
			});
		},

		particle: function(){
			var init = function(){
				var isMobile = navigator.userAgent &&
				navigator.userAgent.toLowerCase().indexOf('mobile') >= 0;
				var isSmall = window.innerWidth < 1000;

				var ps = new ParticleSlider({
					ptlGap: isMobile || isSmall ? 0 : 0,
					ptlSize: isMobile || isSmall ? 1 : 1,
					width: 1e9,
					height: 1e9,
					monochrome: true,
					color: '#60d9eb',
					showArrowControls: false
				});
			};

			if(($body.hasClass('device-lg')) || ($body.hasClass('device-md'))) {
				var psScript = document.createElement('script');
				psScript.addEventListener ? psScript.addEventListener('load', init, false) : psScript.onload = init;
				psScript.src = 'dist/js/particleslider.js';
				psScript.setAttribute('type', 'text/javascript');
				document.body.appendChild(psScript);
			}
		},

		owlCaptionInit: function(){
			if( $owlCarouselEl.length > 0 ){
				$owlCarouselEl.each( function(){
					var element = $(this);
					if( element.find('.owl-dot').length > 0 ) {
						element.find('.owl-controls').addClass('with-carousel-dots');
					}
				});
			}
		}
	};

	BRETTBASH.portfolio = {

		init: function(){

			BRETTBASH.portfolio.ajaxload();

		},

		portfolioDescMargin: function(){
			var $portfolioOverlayEl = $('.portfolio-overlay');
			if( $portfolioOverlayEl.length > 0 ){
				$portfolioOverlayEl.each(function() {
					var element = $(this);
					if( element.find('.portfolio-desc').length > 0 ) {
						var portfolioOverlayHeight = element.outerHeight();
						var portfolioOverlayDescHeight = element.find('.portfolio-desc').outerHeight();
						if( element.find('a.left-icon').length > 0 || element.find('a.right-icon').length > 0 || element.find('a.center-icon').length > 0 ) {
							var portfolioOverlayIconHeight = 40 + 20;
						} else {
							var portfolioOverlayIconHeight = 0;
						}
						var portfolioOverlayMiddleAlign = ( portfolioOverlayHeight - portfolioOverlayDescHeight - portfolioOverlayIconHeight ) / 2
						element.find('.portfolio-desc').css({ 'margin-top': portfolioOverlayMiddleAlign });
					}
				});
			}
		},

		arrange: function(){
			BRETTBASH.initialize.setFullColumnWidth( $portfolio );
		},

		ajaxload: function(){
			$('.portfolio-ajax .portfolio-item a.center-icon').click( function(e) {
				var portPostId = $(this).parents('.portfolio-item').attr('id');
				if( !$(this).parents('.portfolio-item').hasClass('portfolio-active') ) {
					BRETTBASH.portfolio.loadItem(portPostId, prevPostPortId);
				}
				e.preventDefault();
			});
		},

		newNextPrev: function( portPostId ){
			var portNext = BRETTBASH.portfolio.getNextItem(portPostId);
			var portPrev = BRETTBASH.portfolio.getPrevItem(portPostId);
			$('#next-portfolio').attr('data-id', portNext);
			$('#prev-portfolio').attr('data-id', portPrev);
		},

		loadItem: function( portPostId, prevPostPortId, getIt ){
			if(!getIt) { getIt = false; }
			var portNext = BRETTBASH.portfolio.getNextItem(portPostId);
			var portPrev = BRETTBASH.portfolio.getPrevItem(portPostId);
			if(getIt == false) {
				BRETTBASH.portfolio.closeItem();
				$portfolioAjaxLoader.fadeIn();
				var portfolioDataLoader = $('#' + portPostId).attr('data-loader');
				$portfolioDetailsContainer.load(portfolioDataLoader, { portid: portPostId, portnext: portNext, portprev: portPrev },
				function(){
					BRETTBASH.portfolio.initializeAjax(portPostId);
					BRETTBASH.portfolio.openItem();
					$portfolioItems.removeClass('portfolio-active');
					$('#' + portPostId).addClass('portfolio-active');
				});
			}
		},

		closeItem: function(){
			if( $portfolioDetails && $portfolioDetails.height() > 32 ) {
				$portfolioAjaxLoader.fadeIn();
				$portfolioDetails.find('#portfolio-ajax-single').fadeOut('600', function(){
					$(this).remove();
				});
				$portfolioDetails.removeClass('portfolio-ajax-opened');
			}
		},

		openItem: function(){
			var noOfImages = $portfolioDetails.find('img').length;
			var noLoaded = 0;

			if( noOfImages > 0 ) {
				$portfolioDetails.find('img').on('load', function(){
					noLoaded++;
					var topOffsetScroll = BRETTBASH.initialize.topScrollOffset();
					if(noOfImages === noLoaded) {
						$portfolioDetailsContainer.css({ 'display': 'block' });
						$portfolioDetails.addClass('portfolio-ajax-opened');
						$portfolioAjaxLoader.fadeOut();
						var t=setTimeout(function(){
							BRETTBASH.widget.loadFlexSlider();
							BRETTBASH.initialize.lightbox();
							BRETTBASH.initialize.resizeVideos();
							$('html,body').stop(true).animate({
								'scrollTop': $portfolioDetails.offset().top - topOffsetScroll
							}, 900, 'easeOutQuad');
						},500);
					}
				});
			} else {
				var topOffsetScroll = BRETTBASH.initialize.topScrollOffset();
				$portfolioDetailsContainer.css({ 'display': 'block' });
				$portfolioDetails.addClass('portfolio-ajax-opened');
				$portfolioAjaxLoader.fadeOut();
				var t=setTimeout(function(){
					BRETTBASH.widget.loadFlexSlider();
					BRETTBASH.initialize.lightbox();
					BRETTBASH.initialize.resizeVideos();
					$('html,body').stop(true).animate({
						'scrollTop': $portfolioDetails.offset().top - topOffsetScroll
					}, 900, 'easeOutQuad');
				},500);
			}
		},

		getNextItem: function( portPostId ){
			var portNext = '';
			var hasNext = $('#' + portPostId).next();
			if(hasNext.length != 0) {
				portNext = hasNext.attr('id');
			}
			return portNext;
		},

		getPrevItem: function( portPostId ){
			var portPrev = '';
			var hasPrev = $('#' + portPostId).prev();
			if(hasPrev.length != 0) {
				portPrev = hasPrev.attr('id');
			}
			return portPrev;
		},

		initializeAjax: function( portPostId ){
			prevPostPortId = $('#' + portPostId);

			$('#next-portfolio, #prev-portfolio').click( function() {
				var portPostId = $(this).attr('data-id');
				$portfolioItems.removeClass('portfolio-active');
				$('#' + portPostId).addClass('portfolio-active');
				BRETTBASH.portfolio.loadItem(portPostId,prevPostPortId);
				return false;
			});

			$('#close-portfolio').click( function() {
				$portfolioDetailsContainer.fadeOut('600', function(){
					$portfolioDetails.find('#portfolio-ajax-single').remove();
				});
				$portfolioDetails.removeClass('portfolio-ajax-opened');
				$portfolioItems.removeClass('portfolio-active');
				return false;
			});
		}

	};

	BRETTBASH.widget = {

		init: function(){

			BRETTBASH.widget.parallax();
			BRETTBASH.widget.animations();
			BRETTBASH.widget.scrollanimations();
			BRETTBASH.widget.rotateText();
			BRETTBASH.widget.youtubeBgVideo();
			BRETTBASH.widget.tabs();
			BRETTBASH.widget.toggles();
			BRETTBASH.widget.accordions();
			BRETTBASH.widget.counter();
			BRETTBASH.widget.roundedSkill();
			BRETTBASH.widget.progress();
			BRETTBASH.widget.flickrFeed();
			BRETTBASH.widget.instagramPhotos( '1601943016.1677ed0.518897fa72c74380921c5020c23c1ad2', '66647d3569a645d68df414fbeddb4a2a' );
			BRETTBASH.widget.twitterFeed();
			BRETTBASH.widget.textRotater();
			BRETTBASH.widget.linkScroll();
			BRETTBASH.widget.extras();

		},

		parallax: function(){
			if( !BRETTBASH.isMobile.any() ){
				/*$.stellar({
					horizontalScrolling: false,
					verticalOffset: 150,
					responsive: true
				});*/
				// ScrollMagic Init
				var parallaxcontrol = new ScrollMagic.Controller();
				var windowHalfX = window.innerHeight / 2;

				// Parallax About
				addAnimations([
					TweenMax.fromTo(".about-prlx .background", 2, {
						y: "-10%"}, {
						y: "20%", ease: Linear.easeNone
					})
				], '.about-prlx', '', -windowHalfX, "180%", parallaxcontrol);

				// Parallax Skills
				addAnimations([
					TweenMax.fromTo(".skills-prlx .background", 2, {
						y: "-8%"}, {
						y: "16%", ease: Linear.easeNone
					})
				], '.skills-prlx', '', -windowHalfX, "200%", parallaxcontrol);

				// Parallax Work
				addAnimations([
					TweenMax.fromTo(".work-prlx .background", 2, {
						y: "-5%"}, {
						y: "11%", ease: Linear.easeNone
					})
				], '.work-prlx', '', -windowHalfX, "300%", parallaxcontrol);

				// Parallax Work Grid
				addAnimations([
					TweenMax.fromTo(".work-grid", 2, {
						y: "15%"}, {
						y: "-30%", ease: Linear.easeNone
					})
				], '.work-grid', '', -windowHalfX, "230%", parallaxcontrol);

				// Parallax Terstimonials
				addAnimations([
					TweenMax.fromTo(".testimonials-prlx .background", 2, {
						y: "-10%"}, {
						y: "20%", ease: Linear.easeNone
					})
				], '.testimonials-prlx', '', -windowHalfX, "180%", parallaxcontrol);

				// Parallax Contact
				addAnimations([
					TweenMax.fromTo(".contact-prlx .background", 2, {
						y: "-8%"}, {
						y: "16%", ease: Linear.easeNone
					})
				], '.contact-prlx', '', -windowHalfX, "180%", parallaxcontrol);

			} else {
				$parallaxEl.addClass('mobile-parallax');
				$parallaxPageTitleEl.addClass('mobile-parallax');
			}
		},

		animations: function(){
			var $dataAnimateEl = $('[data-animate]');
			if( $dataAnimateEl.length > 0 ){
				if( $body.hasClass('device-lg') || $body.hasClass('device-md') || $body.hasClass('device-sm') ){
					$dataAnimateEl.each(function(){
						var element = $(this),
							animationDelay = element.attr('data-delay'),
							animationDelayTime = 0;

						if( animationDelay ) { animationDelayTime = Number( animationDelay ) + 500; } else { animationDelayTime = 500; }

						if( !element.hasClass('animated') ) {
							element.addClass('not-animated');
							var elementAnimation = element.attr('data-animate');
							element.appear(function () {
								setTimeout(function() {
									element.removeClass('not-animated').addClass( elementAnimation + ' animated');
								}, animationDelayTime);
							},{accX: 0, accY: -120},'easeInCubic');
						}
					});
				}
			}
		},

		scrollanimations: function() {
			// ::
			// SCROLLMAGIC ---------------------------::
			// ____
			// ScrollMagic Init
			var controller = new ScrollMagic.Controller();
			var windowHalfX = window.innerHeight / 2;

			// About Headliner
			addAnimations([
				TweenMax.fromTo("#section-abt .headliner .glitch", 0.6, {
					opacity: "0", y: 60}, {
					opacity: "1", y: 0, ease: Power1.easeOut
				}),
				TweenMax.fromTo("#section-abt .headliner h1", 0.5, {
					opacity: "0", y: 38}, {
					opacity: "1", y: 0, delay: 0.6, ease: Power1.easeOut
				}),
				TweenMax.fromTo("#section-abt .headliner h3", 0.5, {
					opacity: "0", y: -38}, {
					opacity: "1", y: 0, delay: 0.6, ease: Power1.easeOut
				}),
				TweenMax.fromTo("#section-abt .headliner h2", 0.5, {
					opacity: "0"}, {
					opacity: "1", delay: 0.9, ease: Power1.easeOut
				}),
				TweenMax.fromTo("#section-abt .headliner .code", 0.5, {
					opacity: "0"}, {
					opacity: "1", delay: 1.2, ease: Power1.easeOut
				})
			], '#section-abt', '', -300, '', controller);

			// About Parallax Area
			addAnimations([
				TweenMax.fromTo(".about-prlx .section-icons", 0.6, {
					opacity: "0", y: -60}, {
					opacity: "1", y: 0, delay: 0.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".about-prlx .sector-title", 0.7, {
					opacity: "0", x: 80}, {
					opacity: "1", x: 0, delay: 0.8, ease: Power3.easeOut
				}),
				TweenMax.fromTo(".about-prlx p", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1, ease: Power2.easeOut
				}),
				TweenMax.fromTo(".about-prlx .g-concrete", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".about-prlx .g-aqua", 0.5, {
					opacity: "0", y: -40}, {
					opacity: "1", y: 0, delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".about-prlx .cordinates", 0.5, {
					opacity: "0"}, {
					opacity: "1", delay: 1.4, ease: Power1.easeOut
				})
			], '.about-prlx', '', -300, '', controller);

			// Twitter
			addAnimations([
				TweenMax.fromTo(".about-profile .profile-right .icon-twitter", 0.6, {
					opacity: "0", scaleX: 6, scaleY: 6, rotation: 120, transformOrigin: "50% 50%"}, {
					opacity: "1", scaleX: 1, scaleY: 1, rotation: 0, delay: 0.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".about-profile .profile-right .flexslider", 0.7, {
					opacity: "0", y: 80}, {
					opacity: "1", y: 0, delay: 0.8, ease: Power1.easeOut
				})
			], '.about-profile', '', -300, '', controller);

			// Quotes
			addAnimations([
				TweenMax.fromTo(".quotes .flexslider", 0.7, {
					opacity: "0", y: 20}, {
					opacity: "1", y: 0, delay: 0.2, ease: Power3.easeOut
				})
			], '.quotes', '', -300, '', controller);

			// Skills Parallax Area
			addAnimations([
				TweenMax.fromTo(".skills-prlx .section-icons", 0.6, {
					opacity: "0", y: -60}, {
					opacity: "1", y: 0, delay: 0.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .sector-title", 0.7, {
					opacity: "0", x: 80}, {
					opacity: "1", x: 0, delay: 0.8, ease: Power3.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .skillbloks", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1, ease: Power2.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .glitch-cordinates", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .g-aqua", 0.5, {
					opacity: "0", y: -40}, {
					opacity: "1", y: 0, delay: 1.7, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .cordinates", 0.5, {
					opacity: "0"}, {
					opacity: "1", delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .end-skillblok", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .skill-intro", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1.5, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".skills-prlx .skill-list", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1.9, ease: Power1.easeOut
				}),
			], '.skills-prlx', '', -250, '', controller);

			// Work Parallax Area
			addAnimations([
				TweenMax.fromTo(".work-prlx .section-icons", 0.6, {
					opacity: "0", y: -60}, {
					opacity: "1", y: 0, delay: 0.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".work-prlx .sector-title", 0.7, {
					opacity: "0", x: 80}, {
					opacity: "1", x: 0, delay: 0.8, ease: Power3.easeOut
				}),
				TweenMax.fromTo(".work-prlx p", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1, ease: Power2.easeOut
				}),
				TweenMax.fromTo(".work-prlx .g-concrete", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".work-prlx .g-aqua", 0.5, {
					opacity: "0", y: -40}, {
					opacity: "1", y: 0, delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".work-prlx .cordinates", 0.5, {
					opacity: "0"}, {
					opacity: "1", delay: 1.4, ease: Power1.easeOut
				})
			], '.work-prlx', '', -300, '', controller);

			// Work Blocks
			var workdelay = 0;
			$(".work-grid .portfolio-item").each(function() {
				addAnimations([
					TweenMax.fromTo(this, 0.6, {
						opacity: "0", y: 80}, {
						opacity: "1", y: 0, delay: workdelay, ease: Power1.easeOut
					})
				], this, '', -250, '', controller);
				workdelay += 0.15;
			});

			// Testimonial Area
			addAnimations([
				TweenMax.fromTo(".testimonials-prlx h5", 0.6, {
					opacity: "0", y: -60}, {
					opacity: "1", y: 0, delay: 0.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".testimonials-prlx .glitch", 0.7, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 0.8, ease: Power3.easeOut
				}),
				TweenMax.fromTo(".testimonials-prlx .lineglitch", 0.8, {
					scaleX: 0, transformOrigin: "50% 50%"}, {
					scaleX: 1, delay: 0.4, ease: Power2.easeOut
				}),
				TweenMax.fromTo(".testimonials-prlx .testimonial-full", 0.6, {
					opacity: "0", y: 60}, {
					opacity: "1", y: 0, delay: 1, ease: Power1.easeOut
				})
			], '.testimonials-prlx', '', -300, '', controller);

			// Contact
			addAnimations([
				TweenMax.fromTo(".contact-prlx .section-icons", 0.6, {
					opacity: "0", y: -60}, {
					opacity: "1", y: 0, delay: 0.2, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".contact-prlx .sector-title", 0.7, {
					opacity: "0", x: 80}, {
					opacity: "1", x: 0, delay: 0.8, ease: Power3.easeOut
				}),
				TweenMax.fromTo(".contact-prlx .contact-content ", 0.8, {
					opacity: "0"}, {
					opacity: "1", delay: 1, ease: Power2.easeOut
				}),
				TweenMax.fromTo(".contact-prlx .g-concrete", 0.5, {
					opacity: "0", y: 40}, {
					opacity: "1", y: 0, delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".contact-prlx .g-aqua", 0.5, {
					opacity: "0", y: -40}, {
					opacity: "1", y: 0, delay: 1.4, ease: Power1.easeOut
				}),
				TweenMax.fromTo(".contact-prlx .cordinates", 0.5, {
					opacity: "0"}, {
					opacity: "1", delay: 1.4, ease: Power1.easeOut
				})
			], '.contact-prlx', '', -100, '', controller);
		},

		rotateText: function(){
			//set animation timing
			var animationDelay = 2500,
				//loading bar effect
				barAnimationDelay = 3800,
				barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
				//letters effect
				lettersDelay = 50,
				//type effect
				typeLettersDelay = 150,
				selectionDuration = 500,
				typeAnimationDelay = selectionDuration + 800,
				//clip effect
				revealDuration = 600,
				revealAnimationDelay = 1500;

			initHeadline();


			function initHeadline() {
				//insert <i> element for each letter of a changing word
				singleLetters($('.bb-headline.letters').find('b'));
				//initialise headline animation
				animateHeadline($('.bb-headline'));
			}

			function singleLetters($words) {
				$words.each(function(){
					var word = $(this),
						letters = word.text().split(''),
						selected = word.hasClass('is-visible');
					for (var i in letters) {
						if(word.parents('.rotate-2').length > 0) letters[i] = '<em>' + letters[i] + '</em>';
						letters[i] = (selected) ? '<i class="in">' + letters[i] + '</i>': '<i>' + letters[i] + '</i>';
					}
					var newLetters = letters.join('');
					word.html(newLetters).css('opacity', 1);
				});
			}

			function animateHeadline($headlines) {
				var duration = animationDelay;
				$headlines.each(function(){
					var headline = $(this);

					if(headline.hasClass('loading-bar')) {
						duration = barAnimationDelay;
						setTimeout(function(){ headline.find('.bb-words-wrapper').addClass('is-loading') }, barWaiting);
					} else if (headline.hasClass('clip')){
						var spanWrapper = headline.find('.bb-words-wrapper'),
							newWidth = spanWrapper.width() + 10
						spanWrapper.css('width', newWidth);
					} else if (!headline.hasClass('type') ) {
						//assign to .bb-words-wrapper the width of its longest word
						var words = headline.find('.bb-words-wrapper b'),
							width = 0;
						words.each(function(){
							var wordWidth = $(this).width();
							if (wordWidth > width) width = wordWidth;
						});
						headline.find('.bb-words-wrapper').css('width', width);
					};

					//trigger animation
					setTimeout(function(){ hideWord( headline.find('.is-visible').eq(0) ) }, duration);
				});
			}

			function hideWord($word) {
				var nextWord = takeNext($word);

				if($word.parents('.bb-headline').hasClass('type')) {
					var parentSpan = $word.parent('.bb-words-wrapper');
					parentSpan.addClass('selected').removeClass('waiting');
					setTimeout(function(){
						parentSpan.removeClass('selected');
						$word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
					}, selectionDuration);
					setTimeout(function(){ showWord(nextWord, typeLettersDelay) }, typeAnimationDelay);

				} else if($word.parents('.bb-headline').hasClass('letters')) {
					var bool = ($word.children('i').length >= nextWord.children('i').length) ? true : false;
					hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
					showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);

				}  else if($word.parents('.bb-headline').hasClass('clip')) {
					$word.parents('.bb-words-wrapper').animate({ width : '2px' }, revealDuration, function(){
						switchWord($word, nextWord);
						showWord(nextWord);
					});

				} else if ($word.parents('.bb-headline').hasClass('loading-bar')){
					$word.parents('.bb-words-wrapper').removeClass('is-loading');
					switchWord($word, nextWord);
					setTimeout(function(){ hideWord(nextWord) }, barAnimationDelay);
					setTimeout(function(){ $word.parents('.bb-words-wrapper').addClass('is-loading') }, barWaiting);

				} else {
					switchWord($word, nextWord);
					setTimeout(function(){ hideWord(nextWord) }, animationDelay);
				}
			}

			function showWord($word, $duration) {
				if($word.parents('.bb-headline').hasClass('type')) {
					showLetter($word.find('i').eq(0), $word, false, $duration);
					$word.addClass('is-visible').removeClass('is-hidden');

				}  else if($word.parents('.bb-headline').hasClass('clip')) {
					$word.parents('.bb-words-wrapper').animate({ 'width' : $word.width() + 10 }, revealDuration, function(){
						setTimeout(function(){ hideWord($word) }, revealAnimationDelay);
					});
				}
			}

			function hideLetter($letter, $word, $bool, $duration) {
				$letter.removeClass('in').addClass('out');

				if(!$letter.is(':last-child')) {
					setTimeout(function(){ hideLetter($letter.next(), $word, $bool, $duration); }, $duration);
				} else if($bool) {
					setTimeout(function(){ hideWord(takeNext($word)) }, animationDelay);
				}

				if($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
					var nextWord = takeNext($word);
					switchWord($word, nextWord);
				}
			}

			function showLetter($letter, $word, $bool, $duration) {
				$letter.addClass('in').removeClass('out');

				if(!$letter.is(':last-child')) {
					setTimeout(function(){ showLetter($letter.next(), $word, $bool, $duration); }, $duration);
				} else {
					if($word.parents('.bb-headline').hasClass('type')) { setTimeout(function(){ $word.parents('.bb-words-wrapper').addClass('waiting'); }, 200);}
					if(!$bool) { setTimeout(function(){ hideWord($word) }, animationDelay) }
				}
			}

			function takeNext($word) {
				return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
			}

			function takePrev($word) {
				return (!$word.is(':first-child')) ? $word.prev() : $word.parent().children().last();
			}

			function switchWord($oldWord, $newWord) {
				$oldWord.removeClass('is-visible').addClass('is-hidden');
				$newWord.removeClass('is-hidden').addClass('is-visible');
			}
		},

		loadFlexSlider: function(){
			var $flexSliderEl = $('.fslider').find('.flexslider');
			if( $flexSliderEl.length > 0 ){
				$flexSliderEl.each(function() {
					var $flexsSlider = $(this),
						flexsAnimation = $flexsSlider.parent('.fslider').attr('data-animation'),
						flexsEasing = $flexsSlider.parent('.fslider').attr('data-easing'),
						flexsDirection = $flexsSlider.parent('.fslider').attr('data-direction'),
						flexsSlideshow = $flexsSlider.parent('.fslider').attr('data-slideshow'),
						flexsPause = $flexsSlider.parent('.fslider').attr('data-pause'),
						flexsSpeed = $flexsSlider.parent('.fslider').attr('data-speed'),
						flexsVideo = $flexsSlider.parent('.fslider').attr('data-video'),
						flexsPagi = $flexsSlider.parent('.fslider').attr('data-pagi'),
						flexsArrows = $flexsSlider.parent('.fslider').attr('data-arrows'),
						flexsThumbs = $flexsSlider.parent('.fslider').attr('data-thumbs'),
						flexsSheight = true,
						flexsUseCSS = false;

					if( !flexsAnimation ) { flexsAnimation = 'slide'; }
					if( !flexsEasing || flexsEasing == 'swing' ) {
						flexsEasing = 'swing';
						flexsUseCSS = true;
					}
					if( !flexsDirection ) { flexsDirection = 'horizontal'; }
					if( !flexsSlideshow ) { flexsSlideshow = true; } else { flexsSlideshow = false; }
					if( !flexsPause ) { flexsPause = 5000; }
					if( !flexsSpeed ) { flexsSpeed = 600; }
					if( !flexsVideo ) { flexsVideo = false; }
					if( flexsDirection == 'vertical' ) { flexsSheight = false; }
					if( flexsPagi == 'false' ) { flexsPagi = false; } else { flexsPagi = true; }
					if( flexsThumbs == 'true' ) { flexsPagi = 'thumbnails'; } else { flexsPagi = flexsPagi; }
					if( flexsArrows == 'false' ) { flexsArrows = false; } else { flexsArrows = true; }

					$flexsSlider.flexslider({
						selector: ".slider-wrap > .slide",
						animation: flexsAnimation,
						easing: flexsEasing,
						direction: flexsDirection,
						slideshow: flexsSlideshow,
						slideshowSpeed: Number(flexsPause),
						animationSpeed: Number(flexsSpeed),
						pauseOnHover: true,
						video: flexsVideo,
						controlNav: flexsPagi,
						directionNav: flexsArrows,
						smoothHeight: flexsSheight,
						useCSS: flexsUseCSS,
						start: function(slider){
							BRETTBASH.widget.animations();
							BRETTBASH.initialize.verticalMiddle();
							slider.parent().removeClass('preloader2');
							BRETTBASH.initialize.lightbox();
							$('.flex-prev').html('<i class="icon-angle-left"></i>');
							$('.flex-next').html('<i class="icon-angle-right"></i>');
							BRETTBASH.portfolio.portfolioDescMargin();
						},
						after: function(){
							if( $portfolio.has('portfolio-full') ) {
								BRETTBASH.portfolio.portfolioDescMargin();
							}
						}
					});
				});
			}
		},

		html5Video: function(){
			$('.portfolio-single-image:has(video),.entry-image.parallax:has(video),#page-title:has(video),#slider:not(.skywood-wrap):has(video),.section:has(video)').each(function(){
				var outerContainerWidth = $(this).outerWidth();
				var outerContainerHeight = $(this).outerHeight();
				var innerVideoWidth = $(this).find('video').outerWidth();
				var innerVideoHeight = $(this).find('video').outerHeight();
				if( innerVideoHeight < outerContainerHeight ) {
					var videoAspectRatio = innerVideoWidth/innerVideoHeight;
					var newVideoWidth = outerContainerHeight * videoAspectRatio;
					var innerVideoPosition = (newVideoWidth - outerContainerWidth) / 2;
					$(this).find('video').css({ 'width': newVideoWidth+'px', 'height': outerContainerHeight+'px', 'left': -innerVideoPosition+'px' });
				} else {
					var innerVideoPosition = (innerVideoHeight - outerContainerHeight) / 2;
					$(this).find('video').css({ 'width': innerVideoWidth+'px', 'height': innerVideoHeight+'px', 'top': -innerVideoPosition+'px' });
				}
			});
		},

		youtubeBgVideo: function(){
			if( $youtubeBgPlayerEl.length > 0 ){
				$youtubeBgPlayerEl.each( function(){
					var element = $(this),
						ytbgVideo = element.attr('data-video'),
						ytbgMute = element.attr('data-mute'),
						ytbgRatio = element.attr('data-ratio'),
						ytbgQuality = element.attr('data-quality'),
						ytbgOpacity = element.attr('data-opacity'),
						ytbgContainer = element.attr('data-container'),
						ytbgOptimize = element.attr('data-optimize'),
						ytbgLoop = element.attr('data-loop'),
						ytbgVolume = element.attr('data-volume'),
						ytbgStart = element.attr('data-start'),
						ytbgStop = element.attr('data-stop'),
						ytbgAutoPlay = element.attr('data-autoplay'),
						ytbgFullScreen = element.attr('data-fullscreen');

					if( ytbgMute == 'false' ) { ytbgMute = false; } else { ytbgMute = true; }
					if( !ytbgRatio ) { ytbgRatio = '16/9'; }
					if( !ytbgQuality ) { ytbgQuality = 'hd720'; }
					if( !ytbgOpacity ) { ytbgOpacity = 1; }
					if( !ytbgContainer ) { ytbgContainer = 'self'; }
					if( ytbgOptimize == 'false' ) { ytbgOptimize = false; } else { ytbgOptimize = true; }
					if( ytbgLoop == 'false' ) { ytbgLoop = false; } else { ytbgLoop = true; }
					if( !ytbgVolume ) { ytbgVolume = 1; }
					if( !ytbgStart ) { ytbgStart = 0; }
					if( !ytbgStop ) { ytbgStop = 0; }
					if( ytbgAutoPlay == 'false' ) { ytbgAutoPlay = false; } else { ytbgAutoPlay = true; }
					if( ytbgFullScreen == 'true' ) { ytbgFullScreen = true; } else { ytbgFullScreen = false; }

					element.mb_YTPlayer({
						videoURL: ytbgVideo,
						mute: ytbgMute,
						ratio: ytbgRatio,
						quality: ytbgQuality,
						opacity: ytbgOpacity,
						containment: ytbgContainer,
						optimizeDisplay: ytbgOptimize,
						loop: ytbgLoop,
						vol: ytbgVolume,
						startAt: ytbgStart,
						stopAt: ytbgStop,
						autoplay: ytbgAutoPlay,
						realfullscreen: ytbgFullScreen,
						showYTLogo: false,
						showControls: false
					});
				});
			}
		},

		tabs: function(){
			var $tabs = $('.tabs:not(.customjs)');
			if( $tabs.length > 0 ) {
				$tabs.each( function(){
					var element = $(this),
						elementSpeed = element.attr('data-speed'),
						tabActive = element.attr('data-active');

					if( !elementSpeed ) { elementSpeed = 400; }
					if( !tabActive ) { tabActive = 0; } else { tabActive = tabActive - 1; }

					element.tabs({
						active: Number(tabActive),
						show: {
							effect: "fade",
							duration: Number(elementSpeed)
						}
					});
				});
			}
		},

		toggles: function(){
			var $toggle = $('.toggle');
			if( $toggle.length > 0 ) {
				$toggle.each( function(){
					var element = $(this),
						elementState = element.attr('data-state');

					if( elementState != 'open' ){
						element.find('.togglec').hide();
					} else {
						element.find('.togglet').addClass("toggleta");
					}

					element.find('.togglet').click(function(){
						$(this).toggleClass('toggleta').next('.togglec').slideToggle(300);
						return true;
					});
				});
			}
		},

		accordions: function(){
			var $accordionEl = $('.accordion');
			if( $accordionEl.length > 0 ){
				$accordionEl.each( function(){
					var element = $(this),
						elementState = element.attr('data-state'),
						accordionActive = element.attr('data-active');

					if( !accordionActive ) { accordionActive = 0; } else { accordionActive = accordionActive - 1; }

					element.find('.acc_content').hide();

					if( elementState != 'closed' ) {
						element.find('.acctitle:eq('+ Number(accordionActive) +')').addClass('acctitlec').next().show();
					}

					element.find('.acctitle').click(function(){
						if( $(this).next().is(':hidden') ) {
							element.find('.acctitle').removeClass('acctitlec').next().slideUp("normal");
							$(this).toggleClass('acctitlec').next().slideDown("normal");
						}
						return false;
					});
				});
			}
		},

		counter: function(){
			var $counterEl = $('.counter:not(.counter-instant)');
			if( $counterEl.length > 0 ){
				$counterEl.each(function(){
					var element = $(this);
					var counterElementComma = $(this).find('span').attr('data-comma');
					if( !counterElementComma ) { counterElementComma = false; } else { counterElementComma = true; }
					if( $body.hasClass('device-lg') || $body.hasClass('device-md') ){
						element.appear( function(){
							BRETTBASH.widget.runCounter( element, counterElementComma );
						},{accX: 0, accY: -120},'easeInCubic');
					} else {
						BRETTBASH.widget.runCounter( element, counterElementComma );
					}
				});
			}
		},

		runCounter: function( counterElement,counterElementComma ){
			if( counterElementComma == true ) {
				counterElement.find('span').countTo({
					formatter: function (value, options) {
						value = value.toFixed(options.decimals);
						value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
						return value;
					}
				});
			} else {
				counterElement.find('span').countTo();
			}
		},

		roundedSkill: function(){
			var $roundedSkillEl = $('.rounded-skill');
			if( $roundedSkillEl.length > 0 ){
				$roundedSkillEl.each(function(){
					var element = $(this);

					var roundSkillSize = element.attr('data-size');
					var roundSkillAnimate = element.attr('data-animate');
					var roundSkillWidth = element.attr('data-width');
					var roundSkillColor = element.attr('data-color');
					var roundSkillTrackColor = element.attr('data-trackcolor');

					if( !roundSkillSize ) { roundSkillSize = 140; }
					if( !roundSkillAnimate ) { roundSkillAnimate = 2000; }
					if( !roundSkillWidth ) { roundSkillWidth = 8; }
					if( !roundSkillColor ) { roundSkillColor = '#0093BF'; }
					if( !roundSkillTrackColor ) { roundSkillTrackColor = 'rgba(0,0,0,0.04)'; }

					var properties = {roundSkillSize:roundSkillSize, roundSkillAnimate:roundSkillAnimate, roundSkillWidth:roundSkillWidth, roundSkillColor:roundSkillColor, roundSkillTrackColor:roundSkillTrackColor};

					if( $body.hasClass('device-lg') || $body.hasClass('device-md') ){
						element.css({'width':roundSkillSize+'px','height':roundSkillSize+'px'}).animate({opacity: '0'}, 10);
						element.appear( function(){
							if (!element.hasClass('skills-animated')) {
								element.css({opacity: '1'});
								BRETTBASH.widget.runRoundedSkills( element, properties );
								element.addClass('skills-animated');
							}
						},{accX: 0, accY: -120},'easeInCubic');
					} else {
						BRETTBASH.widget.runRoundedSkills( element, properties );
					}
				});
			}
		},

		runRoundedSkills: function( element, properties ){
			element.easyPieChart({
				size: Number(properties.roundSkillSize),
				animate: Number(properties.roundSkillAnimate),
				scaleColor: false,
				trackColor: properties.roundSkillTrackColor,
				lineWidth: Number(properties.roundSkillWidth),
				lineCap: 'square',
				barColor: properties.roundSkillColor
			});
		},

		progress: function(){
			var $progressEl = $('.progress');
			if( $progressEl.length > 0 ){
				$progressEl.each(function(){
					var element = $(this),
						skillsBar = element.parent('li'),
						skillValue = skillsBar.attr('data-percent');

					if( $body.hasClass('device-lg') || $body.hasClass('device-md') ){
						element.appear( function(){
							if (!skillsBar.hasClass('skills-animated')) {
								element.find('.counter-instant span').countTo();
								skillsBar.find('.progress').css({width: skillValue + "%"}).addClass('skills-animated');
							}
						},{accX: 0, accY: -120},'easeInCubic');
					} else {
						element.find('.counter-instant span').countTo();
						skillsBar.find('.progress').css({width: skillValue + "%"});
					}
				});
			}
		},

		flickrFeed: function(){
			var $flickrFeedEl = $('.flickr-feed');
			if( $flickrFeedEl.length > 0 ){
				$flickrFeedEl.each(function() {
					var element = $(this),
						flickrFeedID = element.attr('data-id'),
						flickrFeedCount = element.attr('data-count'),
						flickrFeedType = element.attr('data-type'),
						flickrFeedTypeGet = 'photos_public.gne';

					if( flickrFeedType == 'group' ) { flickrFeedTypeGet = 'groups_pool.gne'; }
					if( !flickrFeedCount ) { flickrFeedCount = 9; }

					element.jflickrfeed({
						feedapi: flickrFeedTypeGet,
						limit: Number(flickrFeedCount),
						qstrings: {
							id: flickrFeedID
						},
						itemTemplate: '<a href="{{image_b}}" title="{{title}}" data-lightbox="gallery-item">' +
											'<img src="{{image_s}}" alt="{{title}}" />' +
									  '</a>'
					}, function(data) {
						BRETTBASH.initialize.lightbox();
					});
				});
			}
		},

		instagramPhotos: function( c_accessToken, c_clientID ){

			if( typeof Instafeed === 'undefined' ) {
				console.log('Instafeed not Defined.');
				return true;
			}

			var $instagramPhotosEl = $('.instagram-photos');
			if( $instagramPhotosEl.length > 0 ){

				$instagramPhotosEl.each(function() {
					var element = $(this),
						instaGramTarget = element.attr('id'),
						instaGramUserId = element.attr('data-user'),
						instaGramTag = element.attr('data-tag'),
						instaGramLocation = element.attr('data-location'),
						instaGramCount = element.attr('data-count'),
						instaGramType = element.attr('data-type'),
						instaGramSortBy = element.attr('data-sortBy'),
						instaGramRes = element.attr('data-resolution');

					if( !instaGramCount ) { instaGramCount = 9; }
					if( !instaGramSortBy ) { instaGramSortBy = 'none'; }
					if( !instaGramRes ) { instaGramRes = 'thumbnail'; }

					if( instaGramType == 'user' ) {

						var feed = new Instafeed({
							target: instaGramTarget,
							get: instaGramType,
							userId: Number(instaGramUserId),
							limit: Number(instaGramCount),
							sortBy: instaGramSortBy,
							resolution: instaGramRes,
							accessToken: c_accessToken,
							clientId: c_clientID
						});

					} else if( instaGramType == 'tagged' ) {

						var feed = new Instafeed({
							target: instaGramTarget,
							get: instaGramType,
							tagName: instaGramTag,
							limit: Number(instaGramCount),
							sortBy: instaGramSortBy,
							resolution: instaGramRes,
							clientId: c_clientID
						});

					} else if( instaGramType == 'location' ) {

						var feed = new Instafeed({
							target: instaGramTarget,
							get: instaGramType,
							locationId: Number(instaGramUserId),
							limit: Number(instaGramCount),
							sortBy: instaGramSortBy,
							resolution: instaGramRes,
							clientId: c_clientID
						});

					} else {

						var feed = new Instafeed({
							target: instaGramTarget,
							get: 'popular',
							limit: Number(instaGramCount),
							sortBy: instaGramSortBy,
							resolution: instaGramRes,
							clientId: c_clientID
						});

					}

					feed.run();
				});
			}
		},

		twitterFeed: function(){
			$.getJSON('include/twitter/tweets.php?username=brettbash&count=6', function(tweets){
				$("#twitter-scroller .slider-wrap").html(sm_format_twitter3(tweets));
			});
		},

		notifications: function( element ){
			toastr.clear();
			var notifyElement = $(element),
				notifyPosition = notifyElement.attr('data-notify-position'),
				notifyType = notifyElement.attr('data-notify-type'),
				notifyMsg = notifyElement.attr('data-notify-msg'),
				notifyCloseButton = notifyElement.attr('data-notify-close');

			if( !notifyPosition ) { notifyPosition = 'toast-top-right'; } else { notifyPosition = 'toast-' + notifyElement.attr('data-notify-position'); }
			if( !notifyMsg ) { notifyMsg = 'Please set a message!'; }
			if( notifyCloseButton == 'true' ) { notifyCloseButton = true; } else { notifyCloseButton = false; }

			toastr.options.positionClass = notifyPosition;
			toastr.options.closeButton = notifyCloseButton;
			toastr.options.closeHtml = '<button><i class="icon-remove"></i></button>';

			if( notifyType == 'warning' ) {
				toastr.warning(notifyMsg);
			} else if( notifyType == 'error' ) {
				toastr.error(notifyMsg);
			} else if( notifyType == 'success' ) {
				toastr.success(notifyMsg);
			} else {
				toastr.info(notifyMsg);
			}

			return false;
		},

		textRotater: function(){
			if( $textRotaterEl.length > 0 ){
				$textRotaterEl.each(function(){
					var element = $(this),
						trRotate = $(this).attr('data-rotate'),
						trSpeed = $(this).attr('data-speed'),
						trSeparator = $(this).attr('data-separator');

					if( !trRotate ) { trRotate = "fade"; }
					if( !trSpeed ) { trSpeed = 1200; }
					if( !trSeparator ) { trSeparator = ","; }

					var tRotater = $(this).find('.t-rotate');

					tRotater.Morphext({
						animation: trRotate,
						separator: trSeparator,
						speed: Number(trSpeed)
					});
				});
			}
		},

		linkScroll: function(){
			$("a[data-scrollto]").click(function(){
				var element = $(this),
					divScrollToAnchor = element.attr('data-scrollto'),
					divScrollSpeed = element.attr('data-speed'),
					divScrollOffset = element.attr('data-offset'),
					divScrollEasing = element.attr('data-easing');

					if( !divScrollSpeed ) { divScrollSpeed = 750; }
					if( !divScrollOffset ) { divScrollOffset = 0; }
					if( !divScrollEasing ) { divScrollEasing = 'easeOutQuad'; }

				$('html,body').stop(true).animate({
					'scrollTop': $( divScrollToAnchor ).offset().top - Number(divScrollOffset)
				}, Number(divScrollSpeed), divScrollEasing);

				var triggerBttn = document.getElementById( 'trigger-overlay' ),
				overlay = document.querySelector( 'div.overlaynav' );
				var hamburger = document.querySelector( 'div.hamburger' );


				if( classie.has( overlay, 'open' ) ) {
					classie.remove( overlay, 'open' );
					classie.add( overlay, 'close' );
					$( 'div.overlaynav' ).one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(e) {
						classie.remove( overlay, 'close' );
						classie.remove( hamburger, 'openburger' );
						jQuery('body').css('overflow', 'auto');
					});
				}

				return false;
			});
		},

		extras: function(){
			$('[data-toggle="tooltip"]').tooltip();
			$('#primary-menu-trigger,#overlay-menu-close').click(function() {
				$( '#primary-menu > ul, #primary-menu > div > ul' ).toggleClass("show");
				return false;
			});
			$('#page-submenu-trigger').click(function() {
				$body.toggleClass('top-search-open', false);
				$pagemenu.toggleClass("pagemenu-active");
				return false;
			});
			$pagemenu.find('nav').click(function(e){
				$body.toggleClass('top-search-open', false);
				$topCart.toggleClass('top-cart-open', false);
			});
			if( BRETTBASH.isMobile.any() ){
				$body.addClass('device-touch');
			}
		}

	};

	BRETTBASH.isMobile = {
		Android: function() {
			return navigator.userAgent.match(/Android/i);
		},
		BlackBerry: function() {
			return navigator.userAgent.match(/BlackBerry/i);
		},
		iOS: function() {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		},
		Opera: function() {
			return navigator.userAgent.match(/Opera Mini/i);
		},
		Windows: function() {
			return navigator.userAgent.match(/IEMobile/i);
		},
		any: function() {
			return (BRETTBASH.isMobile.Android() || BRETTBASH.isMobile.BlackBerry() || BRETTBASH.isMobile.iOS() || BRETTBASH.isMobile.Opera() || BRETTBASH.isMobile.Windows());
		}
	};

	BRETTBASH.documentOnResize = {

		init: function(){

			var t = setTimeout( function(){
				BRETTBASH.header.topsocial();
				BRETTBASH.header.fullWidthMenu();
				BRETTBASH.header.overlayMenu();
				BRETTBASH.initialize.fullScreen();
				BRETTBASH.initialize.verticalMiddle();
				BRETTBASH.initialize.maxHeight();
				BRETTBASH.initialize.testimonialsGrid();
				BRETTBASH.initialize.contactForm();
				BRETTBASH.slider.captionPosition();
				BRETTBASH.portfolio.arrange();
				BRETTBASH.widget.html5Video();
				BRETTBASH.initialize.dataStyles();
				BRETTBASH.initialize.dataResponsiveHeights();
			}, 500 );

		}

	};

	BRETTBASH.documentOnReady = {

		init: function(){
			BRETTBASH.initialize.init();
			BRETTBASH.header.init();
			if( $slider.length > 0 ) { BRETTBASH.slider.init(); }
			if( $portfolio.length > 0 ) { BRETTBASH.portfolio.init(); }
			BRETTBASH.widget.init();
			BRETTBASH.documentOnReady.windowscroll();

			$('.reagan-outdoor').Chocolat({
				imageSelector: '.project-image',
				setTitle: 'Reagan Outdoor Advertising',
				loop: true,
				enableZoom: true,
				images: [
					{src:'dist/images/work/reagan/bulletins-laptop.jpg'},
					{src:'dist/images/work/reagan/product-digital01.jpg'},
					{src:'dist/images/work/reagan/product-digital02.jpg'},
					{src:'dist/images/work/reagan/product-digital03.jpg'},
					{src:'dist/images/work/reagan/markets01.jpg'},
					{src:'dist/images/work/reagan/markets02.jpg'},
					{src:'dist/images/work/reagan/search.jpg'},
					{src:'dist/images/work/reagan/gives-back.jpg'},
					{src:'dist/images/work/reagan/mobile.jpg'}
				]
			});

			$('.dun4me').Chocolat({
				imageSelector: '.project-image',
				setTitle: 'Dun4me',
				loop: true,
				enableZoom: true,
				images: [
					{src:'dist/images/work/dun4me/devices.jpg'},
					{src:'dist/images/work/dun4me/buyer-messages.jpg'},
					{src:'dist/images/work/dun4me/project-bids.jpg'},
					{src:'dist/images/work/dun4me/buyer-projects.jpg'},
					{src:'dist/images/work/dun4me/i-want-this.jpg'},
					{src:'dist/images/work/dun4me/project.jpg'},
					{src:'dist/images/work/dun4me/followers.jpg'}
				]
			});
		},

		windowscroll: function(){

			// var headerOffset = $header.offset().top;
			// var headerWrapOffset = $headerWrap.offset().top;

			$window.on( 'scroll', function(){

				BRETTBASH.initialize.goToTopScroll();
				$('body.open-header.close-header-on-scroll').removeClass("side-header-open");
				BRETTBASH.slider.sliderParallax();
				BRETTBASH.slider.sliderElementsFade();

			});

			if( $onePageMenuEl.length > 0 ){
				$window.scrolled(function() {
					BRETTBASH.header.onepageScroller();
				});
			}
		}

	};

	BRETTBASH.documentOnLoad = {

		init: function(){

			$('.start_loader').delay(1800).fadeOut(850, function() {
	            $body.css("overflow", "auto");
	        });
			BRETTBASH.slider.captionPosition();
			BRETTBASH.slider.particle();
			BRETTBASH.initialize.maxHeight();
			BRETTBASH.initialize.testimonialsGrid();
			BRETTBASH.initialize.contactForm();
			BRETTBASH.initialize.verticalMiddle();
			BRETTBASH.portfolio.portfolioDescMargin();
			BRETTBASH.portfolio.arrange();
			BRETTBASH.widget.loadFlexSlider();
			BRETTBASH.widget.html5Video();
			BRETTBASH.slider.owlCaptionInit();
		}

	};

	var $window = $(window),
		$body = $('body'),
		$wrapper = $('#wrapper'),
		$header = $('#header'),
		$headerWrap = $('#header-wrap'),
		oldHeaderClasses = $header.attr('class'),
		oldHeaderWrapClasses = $headerWrap.attr('class'),
		stickyMenuClasses = $header.attr('data-sticky-class'),
		defaultLogo = $('#logo').find('.standard-logo'),
		defaultLogoWidth = defaultLogo.find('img').outerWidth(),
		retinaLogo = $('#logo').find('.retina-logo'),
		defaultLogoImg = defaultLogo.find('img').attr('src'),
		retinaLogoImg = retinaLogo.find('img').attr('src'),
		defaultDarkLogo = defaultLogo.attr('data-dark-logo'),
		retinaDarkLogo = retinaLogo.attr('data-dark-logo'),
		$pagemenu = $('#page-menu'),
		$onePageMenuEl = $('.one-page-menu'),
		onePageGlobalOffset = 0,
		$portfolio = $('#portfolio'),
		$slider = $('#slider'),
		$sliderParallaxEl = $('.slider-parallax'),
		$pageTitle = $('#page-title'),
		$portfolioItems = $('.portfolio-ajax').find('.portfolio-item'),
		$portfolioDetails = $('#portfolio-ajax-wrap'),
		$portfolioDetailsContainer = $('#portfolio-ajax-container'),
		$portfolioAjaxLoader = $('#portfolio-ajax-loader'),
		prevPostPortId = '',
		$topSearch = $('#top-search'),
		$topCart = $('#top-cart'),
		$verticalMiddleEl = $('.vertical-middle'),
		$topSocialEl = $('#top-social').find('li'),
		$siStickyEl = $('.si-sticky'),
		$dotsMenuEl = $('.dots-menu'),
		$goToTopEl = $('#gotoTop'),
		$fullScreenEl = $('.full-screen'),
		$commonHeightEl = $('.common-height'),
		$testimonialsGridEl = $('.testimonials-grid'),
		$pageSectionEl = $('.page-section'),
		$owlCarouselEl = $('.owl-carousel'),
		$parallaxEl = $('.parallax'),
		$parallaxPageTitleEl = $('.page-title-parallax'),
		$youtubeBgPlayerEl = $('.yt-bg-player'),
		$textRotaterEl = $('.text-rotater');

	$(document).ready( BRETTBASH.documentOnReady.init );
	$window.load( BRETTBASH.documentOnLoad.init );
	$window.on( 'resize', BRETTBASH.documentOnResize.init );

})(jQuery);
